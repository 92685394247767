const getLoginHost = () => {
	if (process.env.PLATFORM === `kimeta`) {
		if (process.env.CONFIG_TYPE === `prod`) {
			return `https://login.kimeta.de`
		} else {
			return `https://loginbeta.kimeta.de`
		}
	} else if (process.env.PLATFORM === `jcbergstrasse`) {
		if (process.env.CONFIG_TYPE === `prod`) {
			return `https://login.jobs-bergstrasse.de`
		} else {
			return `https://loginbetajobcenter.kimeta.de`
		}
	}
}

export const getChangePasswordEndpoint = () => {
	return `${getLoginHost()}/Manage/ChangePassword`
}

export const getDeleteAccountEndpoint = () => {
	return `${getLoginHost()}/Manage/DeleteAccount`
}

export const getTokenEndpoint = () => {
	return `${getLoginHost()}/connect/token`
}

export const getAuthorizeEndpoint = () => {
	return `${getLoginHost()}/connect/authorize`
}

export const getEndsessionEndpoint = () => {
	return `${getLoginHost()}/connect/endsession`
}

export const getUsersEndpoint = () => {
	if (process.env.API_PROXY) {
		return `api/users`
	} else if (process.env.CONFIG_TYPE === `prod`) {
		return `https://api.kimeta.de/v1/${process.env.PLATFORM}/Users`
	} else {
		return `http://test-apps-2.kiad.local:5010/v1/${process.env.PLATFORM}/Users`
	}
}

export const getForUserEndpoint = () => {
	if (process.env.API_PROXY) {
		return `api/forUser`
	} else if (process.env.CONFIG_TYPE === `prod`) {
		return `https://api.kimeta.de/v1/${process.env.PLATFORM}/forUser`
	} else {
		return `http://test-apps-2.kiad.local:5010/v1/${process.env.PLATFORM}/forUser`
	}
}

export const getJobsEndpoint = () => {
	if (process.env.API_PROXY) {
		return `api/jobs`
	} else if (process.env.CONFIG_TYPE === `prod`) {
		return `https://api.kimeta.de/v1/${process.env.PLATFORM}/Jobs`
	} else {
		return `http://test-apps-2.kiad.local:5010/v1/${process.env.PLATFORM}/Jobs`
	}
}

export const getAutocompleteJobEndpoint = () => {
	if (process.env.API_PROXY) {
		return `api/autocomplete/job`
	} else if (process.env.CONFIG_TYPE === `prod`) {
		return `https://api.kimeta.de/v1/${process.env.PLATFORM}/AutoCompletion/JobTitle/forUser`
	} else {
		return `http://test-apps-2.kiad.local:5010/v1/${process.env.PLATFORM}/AutoCompletion/JobTitle/forUser`
	}
}

export const getAutocompleteLocationEndpoint = () => {
	if (process.env.API_PROXY) {
		return `api/autocomplete/location`
	} else if (process.env.CONFIG_TYPE === `prod`) {
		return `https://api.kimeta.de/v1/${process.env.PLATFORM}/AutoCompletion/Location/forUser`
	} else {
		return `http://test-apps-2.kiad.local:5010/v1/${process.env.PLATFORM}/AutoCompletion/Location/forUser`
	}
}

export const getStatisticsEndpoint = () => {
	if (process.env.API_PROXY) {
		return `api/statistics`
	} else if (process.env.CONFIG_TYPE === `prod`) {
		return `https://api.kimeta.de/v1/${process.env.PLATFORM}/Statistics`
	} else {
		return `http://test-apps-2.kiad.local:5010/v1/${process.env.PLATFORM}/Statistics`
	}
}

export const getSeoEndpoint = () => {
	if (process.env.API_PROXY) {
		return `api/seo`
	} else if (process.env.CONFIG_TYPE === `prod`) {
		return `https://seoapi.kimeta.de/seo`
	} else {
		return `https://seoapi.kimeta.de/seo`
	}
}

export const getNotificationsEndpoint = () => {
	if (process.env.CONFIG_TYPE === 'prod') {
		return 'https://userfeatureapi.kimeta.de/api/Notification'
	} else {
		return 'http://test-web-mr:52092/api/Notification'
	}
}

export const getPdfDownloadEndpoint = () => {
	if (process.env.CONFIG_TYPE === 'prod') {
		return 'https://next.kimeta.de/pdfCreator/RPNext'
	} else {
		return 'http://test-docker-mr-2:9001/RPNext'
	}
}
