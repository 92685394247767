import Link from 'next/link'
import Heart from 'shared/icons/002-herz.svg'
import { linkAccentColor } from 'config/style'
import translate from 'config/translate'

export const iconHeightDesktop = 24
export const iconHeightMobile = 30
export const iconFontSize = 10

const BookmarkNumber: React.FC<{
	count?: number
	isTallHeader?: boolean
	lang: string
}> = ({ count, isTallHeader, lang }) => (
	<>
		{!count ? (
			<label
				htmlFor="bookmark-help-toggle"
				className={isTallHeader ? 'bookmark has-tall-header' : 'bookmark'}
			>
				<input id="bookmark-help-toggle" type="checkbox" />
				<i className="bookmark-amount">0</i>
				<p>Du hast noch keine Jobs favorisiert.</p>
				<Heart />
				<span>{translate('Favoriten', lang)}</span>
			</label>
		) : (
			<Link href="/bookmarks">
				<a
					className={isTallHeader ? 'bookmark has-tall-header' : 'bookmark'}
					rel="nofollow"
				>
					<Heart />
					<span>{translate('Favoriten', lang)}</span>
					<i className="bookmark-amount">{count}</i>
				</a>
			</Link>
		)}
		<style jsx>{`
			input {
				display: none;
			}
			label p {
				display: none;
				position: absolute;
				right: -14px;
				top: 100%;
				z-index: 1;
				background: white;
				white-space: nowrap;
				padding: 20px 10px;
				border: 1px solid #e9eaea;
				box-shadow: -4px 7px 15px -7px rgba(0, 0, 0, 0.75);
			}
			label i {
				display: none;
			}
			label p:before {
				content: '';
				display: block;
				position: absolute;
				top: 0;
				right: 36px;
				width: 18px;
				height: 18px;
				transform: rotate(45deg) translateY(-13px);
				border-left: 1px solid #e9eaea;
				border-top: 1px solid #e9eaea;
				background: white;
			}
			label #bookmark-help-toggle:checked + p {
				display: initial;
			}

			a,
			label {
				min-width: 30px;
				position: relative;
				display: flex;
				align-items: center;
				flex-direction: column;
				text-decoration: none;
			}
			a :global(svg),
			label :global(svg) {
				position: relative;
				top: 1px;
				height: ${iconHeightDesktop}px;
			}
			a:hover span,
			label:hover span {
				text-decoration: underline;
				color: ${linkAccentColor};
			}
			a:hover :global(svg),
			label:hover :global(svg) {
				fill: ${linkAccentColor};
			}
			span {
				margin-top: 2px;
				color: black;
				font-size: ${iconFontSize}px;
			}
			a:focus {
				color: rgb(142, 164, 7);
			}
			i {
				font-style: normal;
				display: flex;
				align-items: center;
				justify-content: center;
				color: rgb(177, 17, 17);
				font-size: 12px;
				font-weight: 600;
				position: absolute;
				top: 0;
				transform: translateY(-40%);
				right: -2px;
				background-color: rgb(241, 244, 241);
				width: 20px;
				height: 20px;
				border: 1px solid rgb(223, 221, 222);
				border-radius: 50%;
			}
			@media (max-width: 768px) {
				span {
					display: none;
				}
				a :global(svg),
				label :global(svg) {
					height: ${iconHeightMobile}px;
				}
				i {
					right: -8px;
				}
			}
		`}</style>
	</>
)

export default BookmarkNumber
