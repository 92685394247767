import Router from 'next/router'
import React from 'react'
import { parse, format } from 'url'
import { useCallback } from 'react'
import { ParsedUrlQueryInput } from 'querystring'
import { UrlObject } from 'url'

const getFormQueryValue = (form: HTMLFormElement, name: string) => {
	if (form[name] instanceof RadioNodeList) {
		const inputList: RadioNodeList = form[name]
		const values: string[] = []
		inputList.forEach(el => {
			const input = el as HTMLInputElement
			if (input.type === 'hidden') {
				values.push(input.value)
			} else if (input.type === 'radio' && input.checked) {
				values.push(input.value)
			}
		})
		return values
	}

	return form[name].value
}

const onSubmitForm = (
	onSubmit?: (event: React.FormEvent<HTMLFormElement>) => void,
	scrollTop?: boolean,
) => (event: React.FormEvent<HTMLFormElement>) => {
	event.preventDefault()
	const form = event.target as HTMLFormElement

	const elements = Array.from(form.elements, item => item as HTMLInputElement)
	const uniqueNames = Array.from(
		new Set(elements.map(el => el.name).filter(name => name !== '')),
	)

	const query = uniqueNames.reduce(
		(q, name) => ({
			...q,
			[name]: getFormQueryValue(form, name),
		}),
		{},
	)

	if (onSubmit) {
		onSubmit(event)
	}

	const scrollTopOnce = () => {
		Router.events.off('routeChangeComplete', scrollTopOnce)
		window.scrollTo(0, 0)
	}

	if (scrollTop) {
		Router.events.on('routeChangeComplete', scrollTopOnce)
	}
	const routingAction = form.method.toLowerCase() === 'post' ? 'replace' : 'push'

	Router[routingAction]({
		pathname: parse(form.action).pathname || Router.pathname,
		query,
	})
	const autoFocusElement = elements.find(el => el.autofocus)
	if (autoFocusElement) {
		autoFocusElement.focus()
	}
}

type Props = Readonly<{
	'aria-label'?: string
	hiddenInputs?: ParsedUrlQueryInput // { [key: string]: string | number | undefined | boolean }
	scrollTop?: boolean
	redirectUrl?: UrlObject
}> &
	React.ComponentProps<'form'>

const Form: React.FC<Props> = ({
	hiddenInputs = {},
	children,
	onSubmit,
	scrollTop = false,
	method = 'get',
	redirectUrl,
	...rest
}) => {
	const onFormSubmit = useCallback(onSubmitForm(onSubmit, scrollTop), [
		onSubmit,
		scrollTop,
	])
	return (
		<form {...rest} method={method} onSubmit={onFormSubmit}>
			{children}
			{Object.entries(hiddenInputs).map(([name, value]) => {
				if (Array.isArray(value)) {
					return (
						<React.Fragment key={name}>
							{(value as string[]).map((v, index) => (
								<input key={index} type="hidden" name={name} value={v} />
							))}
						</React.Fragment>
					)
				} else if (typeof value !== 'undefined') {
					return (
						<input
							key={name}
							type="hidden"
							name={name}
							value={value as (string | number)}
						/>
					)
				}
			})}
			{method !== 'get' ? (
				<input
					name="redirectUrl"
					type="hidden"
					value={format(redirectUrl as URL)}
				/>
			) : null}
		</form>
	)
}

export const requestSubmit = (formName: string) => {
	const form: HTMLFormElement | null = document.forms.namedItem(formName)
	if (form) {
		const elements = Array.from(form.elements, item => item as HTMLInputElement)
		const element = elements.find(item => {
			return item.type === 'submit'
		})
		if (element) {
			element.click()
		} else {
			const submitter = document.createElement('input')
			submitter.type = 'submit'
			submitter.hidden = true
			form.appendChild(submitter)
			submitter.click()
			form.removeChild(submitter)
		}
	}
}

export default Form
