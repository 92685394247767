import { useEffect, useState, useRef } from 'react'
import Cookie from 'js-cookie'
import { isMobile, isSafariDesktop } from './userAgentParser'
import { skipTrackingParamName } from './mutation/index'
import { ParsedUrlQuery } from 'querystring'

export const useIsMobile = () => {
	const [isMobileState, setIsMobileState] = useState(isMobile())
	useEffect(() => {
		const checkIsMobile = () => {
			if (isMobile() !== isMobileState) {
				setIsMobileState(isMobile())
			}
		}
		window.addEventListener('resize', checkIsMobile)
		return () => {
			window.removeEventListener('resize', checkIsMobile)
		}
	}, [isMobileState])
	return isMobileState
}

export const useIsSafariDesktop = () => {
	const [isSafariDesktopState, setIsSafariDesktopState] = useState(false)
	useEffect(() => {
		setIsSafariDesktopState(isSafariDesktop())
	}, [])
	return isSafariDesktopState
}

export const useTrackPageImpression = (
	title: string,
	path: string,
	query: ParsedUrlQuery,
) => {
	useEffect(() => {
		if (query[skipTrackingParamName]) {
			return
		}

		const pageImpression = { path, title }

		if (Cookie.get('TRACK_LOG')) {
			console.log(JSON.stringify(pageImpression))
		}
	}, [path])
}

export const useBodyScrollLock = () => {
	const [isLocked, setIsLocked] = useState(false)
	const scrollPos = useRef<number>()
	useEffect(() => {
		if (isLocked) {
			scrollPos.current = window.pageYOffset
			document.body.style.overflow = 'hidden'
			document.body.style.position = 'fixed'
			document.body.style.top = `-${scrollPos.current}px`
			document.body.style.width = '100%'
		} else {
			document.body.style.overflow = 'auto'
			document.body.style.position = 'static'
			document.body.style.removeProperty('width')
			if (scrollPos.current) {
				window.scrollTo(0, scrollPos.current)
			}
		}

		return () => {
			document.body.style.overflow = 'auto'
			document.body.style.position = 'static'
			document.body.style.removeProperty('width')
			if (scrollPos.current) {
				window.scrollTo(0, scrollPos.current)
			}
		}
	})
	return setIsLocked
}
