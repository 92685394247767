import Link from 'next/link'
import { accentColor, linkAccentColor } from 'config/style'
import translate from 'config/translate'
import { adHeadline, adText } from 'config/locales/de'

export const SecondaryNavItem: React.FC<{ href: string; isActive: boolean }> = ({
	children,
	href,
	isActive,
}) => (
	<li>
		{isActive ? (
			<label htmlFor="secondary-nav-toggle">{children}</label>
		) : (
			<Link href={href}>
				<a rel="nofollow">{children}</a>
			</Link>
		)}
		<style jsx>{`
			a,
			label {
				padding: 15px 8px;
				text-decoration: none;
				display: flex;
				align-items: center;
				justify-content: space-between;
			}

			li + li {
				border-top: 1px solid #e9eaea;
			}

			label {
				cursor: pointer;
			}

			a,
			a:visited {
				color: #333;
			}

			li label,
			li:hover a {
				color: ${linkAccentColor};
			}

			label :global(svg),
			li:hover :global(svg) {
				fill: ${linkAccentColor};
			}

			a :global(svg),
			label :global(svg) {
				height: 20px;
			}
		`}</style>
	</li>
)

const SecondaryNavWithoutItems: React.FC<{
	isUserLoggedIn: boolean
	redirectPath: string
	lang: string
}> = ({ isUserLoggedIn, redirectPath, lang, children }) => (
	<nav className="secondary-nav">
		{children ? <ul>{children}</ul> : null}
		{isUserLoggedIn ? (
			<a
				rel="nofollow"
				href={`/api/logout?url=${encodeURIComponent(redirectPath)}`}
			>
				{translate('Abmelden', lang)}
			</a>
		) : (
			<>
				<h4>{translate(adHeadline, lang)}</h4>
				<p>{translate(adText, lang)}</p>
				<a
					rel="nofollow"
					href={`/api/login?url=${encodeURIComponent(redirectPath)}`}
				>
					{translate('Anmelden', lang)}
				</a>
				<div>
					<span>{translate('Noch kein Konto?', lang)}</span>
					<a
						rel="nofollow"
						href={`/api/login?url=${encodeURIComponent(redirectPath)}`}
					>
						{translate('Jetzt registrieren', lang)}
					</a>
				</div>
			</>
		)}
		<style jsx>{`
			h4,
			p,
			div {
				margin: 0 30px;
			}
			div {
				display: flex;
				justify-content: space-between;
				font-size: 14px;
				font-weight: 400;
				padding-bottom: 14px;
			}
			div a {
				color: ${linkAccentColor};
			}
			p {
				line-height: 1.3;
				font-weight: 300;
				font-size: 15px;
			}
			h4 {
				font-size: 18px;
				margin-top: 34px;
				margin-bottom: 10px;
			}
			ul + h4 {
				margin-top: 16px;
			}
			ul {
				position: relative;
				padding: 0px 10px;
				margin: 20px 10px;
				background: white;
				list-style: none;
				font-size: 15px;
				border: 1px solid #e9eaea;
			}

			nav > a {
				color: white;
				text-decoration: none;
				background: ${accentColor};
				padding: 10px;
				margin: 30px;
				display: flex;
				border-radius: 5px;
				align-items: center;
				justify-content: center;
				font-weight: 500;
			}

			nav {
				position: absolute;
				height: auto;
				height: initial;
				background: lightgray;
				top: 100%;
				left: 0;
				right: 0;
				z-index: -1;
				transform: translateY(-120%);
				transition: transform 0.3s;
				opacity: 1;
				background: #f4f3f3;
				border-bottom: 1px solid rgb(226, 226, 226);
				padding-bottom: 10px;
			}

			ul:before {
				content: '';
				display: block;
				position: absolute;
				top: -3px;
				right: 14px;
				width: 15px;
				height: 15px;
				transform: rotate(45deg) translateY(-8px);
				border-left: 1px solid #e9eaea;
				border-top: 1px solid #e9eaea;
				background: white;
			}
			@media (min-width: 769px) {
				ul:before {
					display: none;
				}
				ul {
					margin: 20px;
					background: #f4f3f3;
				}
				nav {
					transform: translateY(-180%);
					background: white;
					transition: opacity 0.2s;
					opacity: 0;
					z-index: 2;
					margin-top: -2px;
					top: 100%;
					right: 0;
					left: initial;
					width: 370px;
					border: 1px solid #e9eaea;
					box-shadow: -4px 7px 15px -7px rgba(0, 0, 0, 0.75);
				}
				nav:before {
					content: '';
					display: block;
					position: absolute;
					top: 0px;
					right: 36px;
					width: 18px;
					height: 18px;
					transform: rotate(45deg) translateY(-13px);
					border-left: 1px solid #e9eaea;
					border-top: 1px solid #e9eaea;
					background: white;
				}
			}
		`}</style>
	</nav>
)

export default SecondaryNavWithoutItems
