import { NextPageContext } from 'next'
import { IncomingMessage } from 'http'
import Router from 'next/router'
import { ParsedUrlQuery, ParsedUrlQueryInput, parse } from 'querystring'
import { format } from 'url'
import { log, logError } from 'shared/Util'

const getBody = (req: IncomingMessage): Promise<string> => {
	return new Promise((resolve, reject) => {
		const data: any[] = []
		req.on('data', chunk => {
			data.push(chunk)
		})
		req.on('end', () => {
			try {
				resolve(data.map(chunk => chunk.toString()).join())
			} catch (err) {
				log(err)
				reject(err)
			}
		})
	})
}

const getMutationParameters = async (ctx: NextPageContext): Promise<ParsedUrlQuery> => {
	if (ctx.req && ctx.req.method !== 'GET') {
		const body = await getBody(ctx.req)
		const mut = parse(body)
		return mut
	}

	return ctx.query
}

export const skipTrackingParamName = 'skt'

export type MutationReturnQuery = {
	'mr-name'?: string
	'mr-suc'?: '1' | '0'
}

const replaceUrl = (
	ctx: NextPageContext,
	redirectUrl: string,
	mutationReturnQuery?: MutationReturnQuery,
) => {
	const { res } = ctx
	const [pathname, queryString] = redirectUrl.split('?')
	const query = queryString ? parse(queryString) : {}
	const newQuery = {
		...query,
		[skipTrackingParamName]: true,
		...(mutationReturnQuery ? mutationReturnQuery : {}),
	}
	if (res) {
		res.writeHead(303, {
			Location: format({ pathname, query: newQuery }),
		})
		res.end()
	} else {
		Router.replace(
			{
				pathname,
				query: newQuery,
			},
			redirectUrl,
		)
	}
}

type MutationCallback = (
	ctx: NextPageContext,
	params: ParsedUrlQueryInput,
) => Promise<boolean>

const mutation = (mutationCallback: MutationCallback) => async (
	ctx: NextPageContext,
): Promise<{}> => {
	try {
		const { req } = ctx
		const params = await getMutationParameters(ctx)
		if (
			params.redirectUrl &&
			typeof params.redirectUrl === 'string' &&
			params.redirectUrl.startsWith('/')
		) {
			if (req && req.method === 'GET') {
				replaceUrl(ctx, params.redirectUrl)
				return {}
			}
			let success
			try {
				success = await mutationCallback(ctx, params)
			} catch (mutationError) {
				logError(mutationError)
				success = false
			}
			replaceUrl(ctx, params.redirectUrl, {
				'mr-name': ctx.pathname,
				'mr-suc': success ? '1' : '0',
			})
			return {}
		}
	} catch (err) {
		logError(err)
	}
	replaceUrl(ctx, '/')
	return {}
}

export default mutation
