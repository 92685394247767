import Heart from 'shared/icons/002-herz.svg'
import Alert from 'shared/icons/066-alert.svg'
import Settings from 'shared/icons/067-einstellungen.svg'
import LastSearches from 'shared/icons/068-letzte-suche.svg'
import SecondaryNavWithoutItems, { SecondaryNavItem } from './SecondaryNavWithoutItems'

const SecondaryNav: React.FC<{
	isUserLoggedIn: boolean
	redirectPath: string
	asPath: string
}> = ({ isUserLoggedIn, redirectPath, asPath }) => (
	<SecondaryNavWithoutItems
		isUserLoggedIn={isUserLoggedIn}
		redirectPath={redirectPath}
		lang="de"
	>
		<SecondaryNavItem href="/bookmarks" isActive={asPath.startsWith('/bookmarks')}>
			<span>Favoriten</span>
			<Heart />
		</SecondaryNavItem>
		<SecondaryNavItem href="/history" isActive={asPath.startsWith('/history')}>
			<span>Letzte Suchen</span>
			<LastSearches />
		</SecondaryNavItem>
		{isUserLoggedIn ? (
			<>
				<SecondaryNavItem
					href="/jobalert"
					isActive={asPath.startsWith('/jobalert')}
				>
					<span>Job-Alert</span>
					<Alert />
				</SecondaryNavItem>
				<SecondaryNavItem
					href="/account"
					isActive={asPath.startsWith('/account')}
				>
					<span>Einstellungen</span>
					<Settings />
				</SecondaryNavItem>
			</>
		) : null}
	</SecondaryNavWithoutItems>
)
export default SecondaryNav
