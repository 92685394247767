const isClient = typeof window !== 'undefined'

export const isIpad2OrLower = () =>
	isClient &&
	navigator.userAgent.match(/iPad/i) !== null &&
	window.devicePixelRatio === 1

export const isIphone4OrLower = () =>
	isClient &&
	navigator.userAgent.match(/iPhone/i) !== null &&
	window.screen.height === 480

export const isMobile = () => isClient && window.innerWidth <= 768

export const isSafariDesktop = () =>
	!!(
		isClient &&
		navigator.userAgent.match(/Macintosh/i) &&
		navigator.userAgent.match(/Safari/i) &&
		!navigator.userAgent.match(/Chrome/i)
	)
