import JsCookies from 'js-cookie'
import { IncomingMessage, ServerResponse } from 'http'

type CookieName = 'apiToken' | 'idToken' | 'lang' | 'refreshToken' | 'visitId'

type Cookies = {
	get: (name: CookieName) => string | undefined
	set: (name: CookieName, value: string, maxAgeInSeconds: number) => void
}

const getDateFromMaxAgeInSeconds = (maxAgeInSeconds: number) =>
	new Date(Date.now() + 1000 * maxAgeInSeconds)

const cookiesByReq = new WeakMap()
interface ServerCookieCtor {
	new (req: IncomingMessage, res: ServerResponse): typeof JsCookies
}

let ServerCookies: ServerCookieCtor

const getCookies = (
	req: IncomingMessage | undefined,
	res: ServerResponse | undefined,
): Cookies => {
	// dead code elimination
	if (typeof window === 'undefined' || process.env.NODE_ENV === 'test') {
		ServerCookies = require('cookies')
	}

	if (req && res) {
		if (!cookiesByReq.has(req)) {
			cookiesByReq.set(req, {})
		}
	}

	const cookies = req && res ? new ServerCookies(req, res) : JsCookies
	return {
		get: name => {
			// On server, the cookies are not "stored" immediatly.
			// Instead cookies.set issues a setHeader call with 'Set-Cookie'.
			// Thus the calls of the user of this class gets surprising behaviour:
			// cookies.set('foo', 'bar')
			// cookies.get('foo') // => undefined
			// This is very unexpected behaviour.
			// The cookiesByReq stores the cookie values per req.
			// As cookiesByReq is a weak map, memory gets cleaned up
			// automatically after req dies (has no pending references).
			if (req && cookiesByReq.has(req) && cookiesByReq.get(req)[name]) {
				return cookiesByReq.get(req)[name]
			}

			return cookies.get(name)
		},
		set: (name, value, maxAgeInSeconds) => {
			if (req) {
				if (!cookiesByReq.has(req)) {
					cookiesByReq.set(req, {})
				}
				cookiesByReq.get(req)[name] = value
			}
			cookies.set(name, value, {
				expires: getDateFromMaxAgeInSeconds(maxAgeInSeconds),
				sameSite: 'strict',
				httpOnly: false,
			} as {
				sameSite: 'strict' | 'lax' | 'none' | undefined
				httpOnly: boolean
				expires: Date
			})
		},
	}
}

export default getCookies
