const PrimaryNav: React.FC<{ isUserLoggedIn: boolean; redirectPath: string }> = ({
	isUserLoggedIn,
	redirectPath,
}) => (
	<nav className="primary-nav">
		{isUserLoggedIn ? (
			<a
				rel="nofollow"
				className="kimetalink mobile-only"
				href={`/api/logout?url=${encodeURIComponent(redirectPath)}`}
			>
				Abmelden
			</a>
		) : (
			<a
				rel="nofollow"
				className="kimetalink mobile-only"
				href={`/api/login?url=${encodeURIComponent(redirectPath)}`}
			>
				Anmelden
			</a>
		)}
		<a
			rel="nofollow"
			className="kimetalink"
			href="https://www.kimeta.de/StaticPage/kimeta"
			target="_blank"
		>
			Über uns
		</a>
		<a
			className="button-link"
			rel="nofollow"
			href="https://sam.kimeta.de/welche-produkte"
			target="_blank"
		>
			Für Arbeitgeber
		</a>

		<a
			rel="nofollow"
			className="imprint mobile-only"
			href="https://www.kimeta.de/StaticPage/Impressum"
			target="_blank"
		>
			Impressum
		</a>
		<style jsx>{`
			nav {
				margin-left: auto;
				display: flex;
				font-size: 13px;
				align-items: center;
			}
			nav a {
				cursor: pointer;
				text-decoration: none;
				font-size: 15px;
				margin: 0 20px;
			}

			nav a:hover {
				color: rgb(142, 164, 7);
			}

			.button-link {
				padding: 11px 20px 12px;
				border-radius: 5px;
				border-width: 1px;
				border-style: solid;
				border-color: rgb(218, 218, 218);
				color: white;
				background: rgb(177, 17, 16);
			}
			.kimetalink:visited {
				color: black;
			}

			.kimetalink {
				color: black;
			}

			nav a.button-link:hover {
				color: white;
				background: rgb(188, 1, 8);
			}
			@media (min-width: 769px) {
				.mobile-only {
					display: none;
				}
				nav {
					justify-content: flex-end;
				}
				nav a {
					margin: 0;
				}
				nav a:first-child {
					margin-left: auto;
				}
				nav a + a {
					margin-left: 20px;
				}
				:global(header:not(.has-children)) nav {
					margin-right: 20px;
				}
			}

			@media (max-width: 768px) {
				.button-link {
					border: 0px;
					background: #f4f3f3;
				}

				nav {
					position: absolute;
					height: auto;
					height: initial;
					flex-direction: column;
					align-items: center;
					background: lightgray;
					top: 100%;
					left: 0;
					right: 0;
					z-index: -1;
					transform: translateY(-110%);
					transition: transform 0.3s;
					opacity: 1;
					background: #f4f3f3;
					border-bottom: 1px solid rgb(226, 226, 226);
				}

				nav a {
					width: 100%;
					text-decoration: none;
					line-height: 52px;
					color: rgb(41, 41, 38);
					font-size: 16px;
					padding: 0 0 0 20px;
					border-bottom: 1px solid rgb(225, 224, 224);
					margin: 0;
				}
				.imprint {
					display: block;
				}
			}
		`}</style>
	</nav>
)

export default PrimaryNav
