import React from 'react'
import Link from 'next/link'
import { useBodyScrollLock } from 'shared/customHooks'
import Image from './Image'
import Burger from 'shared/icons/001-burgermenue.svg'
import Close from 'shared/icons/015-X-tag.svg'
import Profile from 'shared/icons/014-profil.svg'
import ProfileActive from 'shared/icons/070-user-active.svg'
import SecondaryNav from './SecondaryNav'
import PrimaryNav from './PrimaryNav'
import BookmarkNumber, {
	iconHeightDesktop,
	iconHeightMobile,
	iconFontSize,
} from './BookmarkNumber'
import { maxWidth } from 'shared/components/Container'

const headerHeightMobile = 59

type Props = {
	count?: number
	isUserLoggedIn: boolean
	redirectPath: string
	asPath: string
}

const Header: React.FC<Props> = ({
	count,
	isUserLoggedIn,
	redirectPath,
	children,
	asPath,
}) => {
	const setBodyScrollLock = useBodyScrollLock()
	return (
		<>
			<header className={React.Children.count(children) > 0 ? 'has-children' : ''}>
				<Link href="/">
					<a rel="nofollow" className="logo">
						<Image
							width="100%"
							src="https://www.kimeta.de/Content/images/Logos/kimeta-de-logo-174@2x.png"
							alt="logo"
						/>
					</a>
				</Link>
				<input
					type="checkbox"
					id="primary-nav-toggle"
					name="primary-nav-toggle"
					onChange={event => setBodyScrollLock(event.target.checked)}
				/>
				<label htmlFor="primary-nav-toggle" className="mobile-nav-button">
					<span>
						<i className="burger">
							<Burger />
						</i>
						<i className="close">
							<Close />
						</i>
					</span>
				</label>
				<PrimaryNav isUserLoggedIn={isUserLoggedIn} redirectPath={redirectPath} />
				<div className="desktop-children">{children}</div>
				<input
					type="checkbox"
					id="secondary-nav-toggle"
					name="secondary-nav-toggle"
					onChange={event => {
						if (window.innerWidth <= 768) {
							setBodyScrollLock(event.target.checked)
						}
					}}
				/>
				<SecondaryNav
					isUserLoggedIn={isUserLoggedIn}
					redirectPath={redirectPath}
					asPath={asPath}
				/>
				<div className="right">
					<BookmarkNumber count={count} lang="de"></BookmarkNumber>

					<label htmlFor="secondary-nav-toggle">
						{isUserLoggedIn ? <ProfileActive /> : <Profile />}
						<span>Mein Kimeta</span>
					</label>
				</div>
				<label htmlFor="primary-nav-toggle" />
				<label htmlFor="secondary-nav-toggle" />
			</header>
			<style jsx>{`
				input,
				label {
					display: none;
				}

				label {
					-webkit-tap-highlight-color: transparent;
				}

				.imprint {
					display: none;
				}

				header {
					position: fixed;
					top: 0;
					left: 0;
					right: 0;
					display: flex;
					flex-wrap: wrap;
					align-items: center;
					height: ${headerHeightMobile}px;
					z-index: 20;
					background: white;
				}
				header:after {
					content: '';
					display: block;
					position: absolute;
					top: 100%;
					height: 3px;
					left: -9999px;
					right: 0;
					background: #ac0;
					border-left: 9999px solid #ac0;
					box-shadow: 9999px 0 0 #ac0;
				}

				header:before {
					content: '';
					position: absolute;
					top: 0;
					bottom: 0;
					left: -9999px;
					right: 0;
					border-left: 9999px solid white;
					box-shadow: 9999px 0 0 white;
					z-index: -5;
				}

				.right label[for='secondary-nav-toggle'] {
					min-width: 30px;
					position: relative;
					display: flex;
					align-items: center;
					flex-direction: column;
					text-decoration: none;
					cursor: pointer;
				}

				label[for='secondary-nav-toggle'] span {
					display: none;
				}

				label[for='secondary-nav-toggle'] :global(svg) {
					height: ${iconHeightMobile}px;
				}
				label[for='secondary-nav-toggle']:hover span {
					text-decoration: underline;
					color: #b4cc04;
				}
				label[for='secondary-nav-toggle']:hover :global(svg) {
					fill: #b4cc04;
				}
				label[for='secondary-nav-toggle'] span {
					margin-top: 2px;
					color: black;
					font-size: ${iconFontSize}px;
				}
				label[for='secondary-nav-toggle']:focus {
					color: rgb(142, 164, 7);
				}

				.logo {
					box-sizing: border-box;
					width: 160px;
					min-height: 51px;
				}

				.desktop-children {
					display: none;
				}

				.right {
					display: flex;
					align-items: center;
					justify-content: flex-end;
					margin: 0 -10px;
					box-sizing: content-box;
				}

				.right > * {
					margin: 0 10px;
				}

				@media (min-width: 769px) {
					.right {
						margin-right: -10px;
						margin-left: 30px;
					}
					header {
						position: relative;
						height: auto;
						max-width: ${maxWidth}px;
						margin: auto;
						flex-wrap: wrap;
						padding: 10px;
						padding-top: 10px;
						padding-bottom: 10px;
					}
					header.has-children {
						padding-top: 5px;
						padding-bottom: 20px;
					}
					header:not(.has-children) :global(.primary-nav) {
						margin-right: 30px;
					}
					header.has-children :global(.primary-nav) {
						min-width: 60%;
						align-self: flex-end;
						flex-grow: 1;
					}
					header.has-children .desktop-children {
						margin-top: 10px;
						display: block;
						flex-grow: 1;
					}
					header.has-children .right {
						margin-top: 10px;
						position: relative;
						top: 4px;
					}
					label[for='secondary-nav-toggle'] :global(svg) {
						height: ${iconHeightDesktop}px;
					}
					label[for='secondary-nav-toggle'] span {
						margin-top: 2px;
						display: block;
					}
					#secondary-nav-toggle:checked ~ label[for='secondary-nav-toggle'] {
						content: '';
						display: block;
						position: fixed;
						top: 0;
						bottom: 0;
						left: 0;
						right: 0;
						background: transparent;
						z-index: 1;
					}
				}

				@media (max-width: 768px) {
					.right {
						order: 3;
						width: 25%;
						padding: 6px;
						flex-shrink: 0;
					}
					.mobile-nav-button {
						order: 1;
						width: 25%;
						z-index: 2;
						flex-shrink: 0;
					}
					.logo {
						order: 2;
						width: 50%;
						flex-shrink: 0;
						display: flex;
						align-items: center;
						justify-content: center;
					}
					.logo :global(picture) {
						width: 120px;
					}
					header {
						flex-wrap: nowrap;
						justify-content: space-between;
					}
					:global(.content-outer) {
						padding-top: ${headerHeightMobile}px;
					}
					:global(.job:not(.invisible)) {
						top: ${headerHeightMobile}px;
					}

					label[for='primary-nav-toggle'] i {
						width: 35px;
					}

					input[type='checkbox'] {
						display: none;
					}

					label {
						display: block;
						cursor: pointer;
					}

					#primary-nav-toggle + label span {
						display: flex;
						justify-content: center;
						align-items: center;
						width: 45px;
						height: 45px;
						padding: 6px;
					}

					#primary-nav-toggle + label .close {
						display: none;
					}
					#primary-nav-toggle:checked + label .burger {
						display: none;
					}

					#primary-nav-toggle:checked + label .close {
						display: block;
					}

					#primary-nav-toggle:checked
						+ label
						+ :global(.primary-nav)
						~ label[for='primary-nav-toggle'],
					#secondary-nav-toggle:checked ~ label[for='secondary-nav-toggle'] {
						content: '';
						display: block;
						position: fixed;
						top: 0;
						bottom: 0;
						left: 0;
						right: 0;
						background: rgba(0, 0, 0, 0.4);
						z-index: -2;
					}
					header {
						justify-content: center;
						border-bottom: 1px solid rgb(225, 224, 224);
						padding-top: 5px;
					}

					header::before {
						display: block;
						top: -40px;
						bottom: 0;
						left: 0;
						right: 0;
						background: white;
						content: '';
						position: absolute;
						z-index: 0;
						border-left: 0px;
						border-top: 43px solid white;
					}
					input {
						display: block;
						z-index: 2;
					}

					.close {
						margin-left: -5px;
						margin-top: -1px;
					}

					#primary-nav-toggle:checked + label + :global(.primary-nav) {
						transform: translateY(0px);
					}

					.logo {
						padding: 0;
						z-index: 2;
					}
				}
				#secondary-nav-toggle:checked + :global(.secondary-nav) {
					transform: translateY(0px) translateX(0px);
					opacity: 1;
				}

				@media print {
					header {
						display: none;
					}
					input {
						display: none;
					}
				}
			`}</style>
		</>
	)
}

export default Header
