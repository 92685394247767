const Image: React.FC<React.HTMLProps<HTMLImageElement>> = ({
	src,
	alt,
	height,
	width,
	...rest
}) => (
	<picture {...rest}>
		<source
			type="image/webp"
			srcSet={
				src
					? `https://img-scale.lipp.dev/?url=${encodeURIComponent(
							src,
					  )}&format=webp`
					: undefined
			}
		/>
		{src && src.match(/\.(png|gif)/) ? (
			<img src={src} alt={alt} />
		) : (
			<>
				<source
					type="image/jpeg"
					srcSet={
						src
							? `https://img-scale.lipp.dev/?url=${encodeURIComponent(src)}`
							: undefined
					}
				/>
				<img
					src={
						src
							? `https://img-scale.lipp.dev/?url=${encodeURIComponent(src)}`
							: undefined
					}
					alt={alt}
				/>
			</>
		)}
		<style jsx>{`
			img {
				width: ${width ? width : 'auto'};
				height: ${height ? height : 'auto'};
			}
			@media print {
				img {
					display: none;
				}
			}
		`}</style>
	</picture>
)

export default Image
