export const maxWidth = 1400
export const padding = 10

const Container: React.FC = ({ children }) => (
	<div>
		{children}
		<style jsx>{`
			div {
				padding: 0 ${padding}px;
				max-width: ${maxWidth}px;
				margin: 0 auto;
				min-height: 100vh;
			}

			@media (max-width: 768px) {
				div {
					padding: 0;
				}
			}
		`}</style>
	</div>
)

export default Container
