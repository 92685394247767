import translate from 'config/translate'

type Props = {
	lang?: string
}

const Footer: React.FC<Props> = ({ lang = '' }) => (
	<>
		<footer>
			<ul>
				<li>
					<a
						rel="nofollow"
						href="https://www.kimeta.de/StaticPage/Impressum"
						target="_blank"
					>
						{translate('Impressum', lang)}
					</a>
				</li>
				<li>
					<a
						rel="nofollow"
						href="https://www.kimeta.de/Content/Downloads/AGB_kimeta.pdf"
						target="_blank"
					>
						{translate('AGB', lang)}
					</a>
				</li>
				<li>
					<a
						rel="nofollow"
						href="https://www.kimeta.de/Content/Downloads/Datenschutzerklaerung.pdf"
						target="_blank"
					>
						{translate('Datenschutzerklärung', lang)}
					</a>
				</li>
				<li>
					<a
						rel="nofollow"
						href="https://www.kimeta.de/StaticPage/Kontakt"
						target="_blank"
					>
						{translate('Kontakt', lang)}
					</a>
				</li>
			</ul>
		</footer>
		<style jsx>{`
			footer {
				display: flex;
				bottom: 0px;
				left: 0px;
				z-index: 20;
				background-color: rgb(85, 85, 85);
				text-align: center;
			}
			footer ul {
				width: 100%;
				margin: 16px 0;
			}
			footer li {
				display: inline-block;
			}
			footer a {
				text-decoration: none;
				margin: 0px 20px;
				color: white;
				font-size: 16px;
			}
			footer a:hover {
				color: #b4cc04;
			}

			@media (max-width: 767px) {
				footer ul {
					margin: 0;
					text-align: left;
				}
				footer li {
					display: block;
					width: 100%;
					border-bottom: 1px solid rgb(101, 101, 101);
					padding: 15px 10px;
				}

				footer li:last-child {
					border-bottom: none;
				}

				footer a {
					font-size: 14px;
					margin: 0;
				}
			}

			@media print {
				footer {
					display: none;
				}
			}
		`}</style>
	</>
)

export default Footer
