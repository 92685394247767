export const adHeadline = 'Mehr drin mit Mein Kimeta'
export const adText =
	'Melde dich an oder erstelle ein kostenloses Konto, um deine Favoriten und letzten Suchen dauerhaft zu speichern und mehr Funktionen freizuschalten.'
export const accountText =
	'Hier kannst du dein Passwort für meinBereich ändern und dein Kundenkonto auch wieder löschen'

export const signupHeadline = 'Schneller Finden mit Mein Kimeta'
export const signupText =
	'Melde dich an oder erstelle ein kostenloses Konto, um deine Suchen dauerhaft zu speichern und nicht jedes Mal eine neue Jobsuche starten zu müssen.'

export default {}
