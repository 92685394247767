import 'isomorphic-unfetch'
import { log } from 'shared/Util'
import { IncomingMessage, ServerResponse } from 'http'
import { NextPageContext } from 'next'
import getCookies from './iso-cookies'
import { encode, ParsedUrlQueryInput } from 'querystring'
import {
	IApplicationFormResponse,
	IApplicationFormSend,
	IJobOfferDetails,
	IJobIndex,
	INotedOffer,
	ISearchResults,
	ISimilarJob,
	IStoredSearch,
	ITokenServerResponse,
	IVisitServerResponse,
	SeoResourceForUrl,
	SeoStartPage,
	IJobOffer,
	AccessToken,
	Notification,
	User,
} from './interfaces'
import {
	getAutocompleteJobEndpoint,
	getUsersEndpoint,
	getSeoEndpoint,
	getTokenEndpoint,
	getForUserEndpoint,
	getJobsEndpoint,
	getAutocompleteLocationEndpoint,
	// getJobIndexEndpoint,
	getStatisticsEndpoint,
} from './api-endpoints'
const jwtDecode = require('jwt-decode')

const retrySleep = 200
const maxRetryCount = 5
export const pageSize = 14

const visitIdExpirationSecs = 60 * 20 // twenty minutes
const apiTokenExpirationSecs = 60 * 60 * 24 * 365 // one year
const tokenExpirationSafetyToleranceMs = 1000 * 120 // two minutes

const isValidToken = (apiToken?: string) => {
	try {
		const { exp, sub }: AccessToken = jwtDecode(apiToken)
		const isExpired =
			new Date(1000 * exp + tokenExpirationSafetyToleranceMs) < new Date()
		return sub && !isExpired
	} catch (_) {
		return false
	}
}

export const useSsrToken = async (ctx: NextPageContext) => {
	if (!ctx.req) {
		return false
	}
	const cookies = getCookies(ctx.req, ctx.res)
	const apiToken = cookies.get('apiToken')
	if (isValidToken(apiToken) || isUserLoggedIn(ctx)) {
		return false
	} else if (apiToken) {
		try {
			await getToken(ctx.req, ctx.res, true)
			return false
		} catch (_) {
			return true
		}
	}

	return true
}

export class FetchError extends Error {
	constructor(res: Response) {
		super(`Fetch ${res.url} failed with status=${res.status} / ${res.statusText}`)
		this.res = res
	}

	res: Response
}

export const getHost = (req: IncomingMessage | undefined) => {
	const host = req
		? process.env.NOW_REGION === 'dev1'
			? 'http://localhost:3000'
			: `https://${req.headers.host}`
		: `${window.location.protocol}//${window.location.host}`
	return host
}

export const getJson = async <Type>(
	req: IncomingMessage | undefined,
	url: string,
	options: RequestInit = {},
	allowedStatusCodes = [200],
	responseCallback?: (res: Response) => void,
) => {
	const hostPrefix = process.env.API_PROXY
		? getHost(req) + '/'
		: req
		? 'http://localhost:3000/'
		: ''
	const fetchUrl = url.startsWith('http') ? url : `${hostPrefix}${url}`
	const resp = await fetch(fetchUrl, options)

	if (responseCallback) {
		responseCallback(resp)
	}

	if (!allowedStatusCodes.includes(resp.status)) {
		throw new FetchError(resp)
	}

	const json: Type = await resp.json()
	return json
}

export const getVisitId = async (
	req: IncomingMessage | undefined,
	res: ServerResponse | undefined,
	userId: string,
	token: string,
) => {
	const cookies = getCookies(req, res)
	const visitId = cookies.get('visitId')
	if (visitId) {
		cookies.set('visitId', visitId, visitIdExpirationSecs)
		return visitId
	}
	const resp = await getJson<IVisitServerResponse>(
		req,
		`${getUsersEndpoint()}/${userId}/Visit`,
		{
			method: 'POST',
			headers: {
				Authorization: `Bearer ${token}`,
			},
		},
	)
	cookies.set('visitId', resp.visitId, visitIdExpirationSecs)
	return resp.visitId
}

// forwards cookies from a fetch Response to a ServerResponse
const forwardCookies = (res: ServerResponse) => (response: Response) => {
	const setCookieHeader = response.headers.get('set-cookie')
	if (setCookieHeader) {
		res.setHeader('Set-Cookie', setCookieHeader)
	}
}

export const getToken = async (
	req: IncomingMessage | undefined,
	res: ServerResponse | undefined,
	renew = false,
) => {
	const cookies = getCookies(req, res)
	const apiToken = cookies.get('apiToken')
	if (apiToken && !renew && isValidToken(apiToken)) {
		return apiToken
	}

	try {
		const { access_token } = await getJson<ITokenServerResponse>(
			req,
			'api/token',
			{
				method: 'POST',
				credentials: 'same-origin',
				headers: req
					? {
							cookie: req.headers.cookie || '',
					  }
					: undefined,
			},
			[200],
			res ? forwardCookies(res) : undefined,
		)
		cookies.set('apiToken', access_token, apiTokenExpirationSecs)
		return access_token
	} catch (err) {
		const isCriticalAuthError = (err: Error | FetchError) => {
			if (err instanceof FetchError) {
				const status = err.res.status
				if (status !== 401 && status !== 403) {
					return true
				}
			}

			return false
		}

		if (isCriticalAuthError(err)) {
			log('auth failed critical', err)
			cookies.set('apiToken', '', 0)
		}
		throw err
	}
}

let ssrToken: string
let ssrRefreshToken: string

export const _resetSsrToken = () => {
	ssrToken = ''
	ssrRefreshToken = ''
}

export const getTokenSsr = async (req: IncomingMessage | undefined, renew = false) => {
	if (!renew && ssrToken) {
		return ssrToken
	}
	const useRefreshToken = renew && ssrRefreshToken

	const { access_token, refresh_token } = await getJson<ITokenServerResponse>(
		req,
		getTokenEndpoint(),
		{
			method: 'POST',
			headers: {
				'content-type': 'application/x-www-form-urlencoded',
			},
			body: useRefreshToken
				? encode({
						client_id: 'SSR',
						grant_type: 'refresh_token',
						refresh_token: ssrRefreshToken,
				  })
				: encode({
						client_id: 'SSR',
						grant_type: 'client_credentials',
						client_secret: process.env.KIMETA_SSR_CLIENT_SECRET,
						scope: 'jobs',
				  }),
		},
	)

	ssrToken = access_token
	ssrRefreshToken = refresh_token

	return access_token
}

const sleep = (ms: number) => new Promise(resolve => setTimeout(resolve, ms))

const outstandingGetTokenPromise = new WeakMap()
const outstandingGetRefreshTokenPromise = new WeakMap()
const outstandingGetVisitIdPromise = new WeakMap()
const clientRefObject = {} // only for client

export const apiCall = async <Type>(
	ctx: NextPageContext | undefined,
	url: (userId: string) => string,
	options: RequestInit = {},
	allowedStatusCodes = [200],
	retryCount = 0,
) => {
	const req = ctx ? ctx.req : undefined
	const res = ctx ? ctx.res : undefined
	const refObject = ctx || clientRefObject
	try {
		if (!outstandingGetTokenPromise.has(refObject)) {
			outstandingGetTokenPromise.set(refObject, getToken(req, res))
		}
		const token = await outstandingGetTokenPromise.get(refObject)
		outstandingGetTokenPromise.delete(refObject)
		const userId = jwtDecode(token).sub
		if (!outstandingGetVisitIdPromise.has(refObject)) {
			outstandingGetVisitIdPromise.set(
				refObject,
				getVisitId(req, res, userId, token),
			)
		}
		const visitId = await outstandingGetVisitIdPromise.get(refObject)
		outstandingGetVisitIdPromise.delete(refObject)
		const result: Type = await getJson<Type>(
			req,
			url(userId),
			{
				...options,
				headers: {
					...options.headers,
					Authorization: `Bearer ${token}`,
					'kiclient-visit': visitId,
					cookie: req ? req.headers.cookie || '' : '',
				},
				credentials: 'same-origin',
			},
			allowedStatusCodes,
			res ? forwardCookies(res) : undefined,
		)
		retryCount = 0
		return result
	} catch (err) {
		outstandingGetTokenPromise.delete(refObject)
		outstandingGetVisitIdPromise.delete(refObject)
		const isAuthError = (err: Error) => {
			if (err instanceof FetchError) {
				return err.res.status === 401 || err.res.status === 403
			} else if (err instanceof Error) {
				return !!err.message.match(/.*invalid.*token/i)
			}

			return false
		}

		if (retryCount < maxRetryCount && isAuthError(err)) {
			if (!outstandingGetRefreshTokenPromise.has(refObject)) {
				outstandingGetRefreshTokenPromise.set(refObject, getToken(req, res, true))
			}
			outstandingGetTokenPromise.delete(refObject)
			outstandingGetVisitIdPromise.delete(refObject)
			await outstandingGetRefreshTokenPromise.get(refObject)
			outstandingGetRefreshTokenPromise.delete(refObject)
			const result: Type = await apiCall<Type>(
				ctx,
				url,
				options,
				allowedStatusCodes,
				maxRetryCount,
			)
			return result
		} else if (
			!isAuthError(err) &&
			err instanceof FetchError &&
			err.res.status !== 404 &&
			retryCount < maxRetryCount
		) {
			await sleep(retrySleep)
			const result: Type = await apiCall<Type>(
				ctx,
				url,
				options,
				allowedStatusCodes,
				retryCount + 1,
			)
			return result
		} else {
			throw err
		}
	}
}

export const apiCallSsr = async <Type>(
	ctx: NextPageContext,
	url: string,
	options: RequestInit = {},
	retryCount = 0,
) => {
	const refObject = ctx
	const req = ctx.req as IncomingMessage
	try {
		if (!outstandingGetTokenPromise.has(refObject)) {
			outstandingGetTokenPromise.set(refObject, getTokenSsr(req))
		}
		const token = await outstandingGetTokenPromise.get(refObject)
		outstandingGetTokenPromise.delete(refObject)
		const result: Type = await getJson<Type>(ctx.req, url, {
			...options,
			headers: {
				...options.headers,
				Authorization: `Bearer ${token}`,
			},
		})
		retryCount = 0
		return result
	} catch (err) {
		outstandingGetTokenPromise.delete(refObject)
		const isAuthError = (err: Error) =>
			(err instanceof FetchError && err.res.status === 401) ||
			err.message.match(/.*invalid.*token/i)
		if (retryCount < maxRetryCount && isAuthError(err)) {
			if (!outstandingGetRefreshTokenPromise.has(refObject)) {
				outstandingGetRefreshTokenPromise.set(refObject, getTokenSsr(req, true))
			}
			outstandingGetTokenPromise.delete(refObject)
			await outstandingGetRefreshTokenPromise.get(refObject)
			outstandingGetRefreshTokenPromise.delete(refObject)
			const result: Type = await apiCallSsr<Type>(ctx, url, options, maxRetryCount)
			return result
		} else if (
			!isAuthError(err) &&
			err instanceof FetchError &&
			retryCount < maxRetryCount
		) {
			await sleep(retrySleep)
			const result: Type = await apiCallSsr<Type>(ctx, url, options, retryCount + 1)
			return result
		} else {
			throw err
		}
	}
}

export type SearchArg = Readonly<{
	freeText?: string
	location?: string
	filters?: readonly string[]
	radius?: string
	page?: number
	searchGroupId?: string
	searchId?: string
}>

export type SearchApiQueryParams = {
	FilterSizePerCategory: number
	OrganicSizeOrder: number
	PremiumMergeStrategyOrder: string
	PremiumSizeOrder: string
	PremiumSortingStrategy: string
	searchTerms: string[]
	IsPaging?: boolean
	Page?: number
	SearchGroupId?: string
	SearchId?: string
}

export const getSearchPages = async (
	ctx: NextPageContext,
	page: number,
	queryParams: ParsedUrlQueryInput,
) => {
	const pages = await Promise.all(
		Array(page + 1)
			.fill(1)
			.map(async (_, pageNum) => {
				const query = encode({
					...queryParams,
					Page: pageNum,
				})

				if (await useSsrToken(ctx)) {
					return apiCallSsr<ISearchResults>(
						ctx,
						`${getJobsEndpoint()}?${query}`,
					)
				}

				return apiCall<ISearchResults>(
					ctx,
					userId => `${getJobsEndpoint()}/forUser/${userId}?${query}`,
				)
			}),
	)

	return {
		...pages[pages.length - 1],
		jobOffers: pages.reduce<IJobOffer[]>(
			(offers, { jobOffers }) => offers.concat(jobOffers),
			[],
		),
	}
}

export const getSearchResults = async (
	ctx: NextPageContext,
	{
		freeText,
		location,
		filters = [],
		radius,
		page = 0,
		searchId,
		searchGroupId,
	}: SearchArg,
) => {
	const searchTerms = [...filters]
	if (freeText) {
		searchTerms.push(`freeTextInput@${freeText}`)
	}

	if (location) {
		searchTerms.push(`location@${location}`)
	}

	if (location && radius) {
		searchTerms.push(`radiusInKm@${radius}`)
	}
	const queryParams: SearchApiQueryParams = {
		FilterSizePerCategory: 100,
		OrganicSizeOrder: pageSize,
		PremiumMergeStrategyOrder: 'TopBottom-Bottom',
		PremiumSizeOrder: '4-2',
		PremiumSortingStrategy: 'PremiumcpcEsscore',
		searchTerms,
		IsPaging: !!searchGroupId,
	}
	if (searchGroupId) {
		queryParams.SearchGroupId = searchGroupId
	}

	if (searchId) {
		queryParams.SearchId = searchId
	}

	return getSearchPages(ctx, page, queryParams)
}

export const getJob = async (ctx: NextPageContext, documentId: string) => {
	if (await useSsrToken(ctx)) {
		return apiCallSsr<IJobOfferDetails>(ctx, `${getJobsEndpoint()}/${documentId}`)
	}

	return apiCall<IJobOfferDetails>(
		ctx,
		userId => `${getJobsEndpoint()}/forUser/${userId}/job/${documentId}`,
	)
}

export const getTopJobs = async (ctx: NextPageContext, documentId: string) => {
	if (await useSsrToken(ctx)) {
		return apiCallSsr<ISimilarJob[]>(
			ctx,
			`${getJobsEndpoint()}/SimilarJobs/${documentId}?size=7`,
		)
	}

	return apiCall<ISimilarJob[]>(
		ctx,
		userId =>
			`${getJobsEndpoint()}/forUser/${userId}/SimilarJobs/${documentId}?size=7`,
	)
}

export const getSimilarJobs = async (ctx: NextPageContext, documentId: string) => {
	if (await useSsrToken(ctx)) {
		return apiCallSsr<ISimilarJob[]>(
			ctx,
			`${getJobsEndpoint()}/SimilarJobs/${documentId}?size=7&useCompanyFilter=true&onlySponsored=false`,
		)
	}

	return apiCall<ISimilarJob[]>(
		ctx,
		userId =>
			`${getJobsEndpoint()}/forUser/${userId}/SimilarJobs/${documentId}?size=7&useCompanyFilter=true&onlySponsored=false`,
	)
}

export const getStoredSearches = async (ctx: NextPageContext) => {
	if (await useSsrToken(ctx)) {
		return []
	}

	try {
		return await apiCall<IStoredSearch[]>(
			ctx,
			userId => `${getUsersEndpoint()}/${userId}/Searches`,
		)
	} catch (err) {
		return []
	}
}

export const deleteStoredSearch = async (ctx: NextPageContext, id: string) => {
	return apiCall(ctx, userId => `${getUsersEndpoint()}/${userId}/Searches/${id}`, {
		method: 'DELETE',
	})
}

// never called from server!!
export const getAutoCompleteJob = async (query: string) =>
	query
		? await apiCall<string[]>(
				undefined,
				userId =>
					`${getAutocompleteJobEndpoint()}/${userId}?Query=${query}&size=4`,
		  )
		: []

// never called from server!!
export const getAutoCompleteLocation = async (query: string) =>
	query
		? apiCall<string[]>(
				undefined,
				userId =>
					`${getAutocompleteLocationEndpoint()}/${userId}?Query=${query}&Max_Results=4`,
		  )
		: []

export const getJobIndex = async (ctx: NextPageContext) => {
	if (await useSsrToken(ctx)) {
		return apiCallSsr<IJobIndex>(ctx, `${getStatisticsEndpoint()}/JobIndex`)
	}

	return apiCall<IJobIndex>(ctx, () => `${getStatisticsEndpoint()}/JobIndex`)
}

export const setNotedOffer = async (
	ctx: NextPageContext,
	offerId: string,
	isNoted: boolean,
) => {
	cachedNotedOffers = undefined
	if (await useSsrToken(ctx)) {
		throw new Error('invalid action')
	}
	const result = await apiCall(
		ctx,
		userId => `${getUsersEndpoint()}/${userId}/NotedOffers/${offerId}`,
		{
			method: isNoted ? 'POST' : 'DELETE',
		},
	)
	return result
}

export const setNotedOfferStatus = async (
	ctx: NextPageContext,
	offerId: string,
	status: number,
) => {
	cachedNotedOffers = undefined
	if (await useSsrToken(ctx)) {
		throw new Error('invalid action')
	}
	const result = await apiCall(
		ctx,
		userId =>
			`${getUsersEndpoint()}/${userId}/NotedOffers/${offerId}/Status?statusId=${status}`,
		{
			method: 'POST',
		},
	)
	return result
}

let cachedNotedOffers: INotedOffer[] | undefined

export const getNotedOffers = async (ctx: NextPageContext) => {
	if (!ctx.req && cachedNotedOffers) {
		return cachedNotedOffers
	}

	if (await useSsrToken(ctx)) {
		return []
	}

	try {
		const result = await apiCall<INotedOffer[]>(
			ctx,
			userId => `${getUsersEndpoint()}/${userId}/NotedOffers`,
		)
		cachedNotedOffers = result
		return result
	} catch (_) {
		return []
	}
}

export const getSeoResourceForUrl = async (
	ctx: NextPageContext,
	url: string,
): Promise<SeoResourceForUrl> =>
	getJson(
		ctx.req,
		// TODO: https://youtrack.kiad.local/issue/KN-1925
		// API-Call muss umgestellt werden, sobald SEO-XML auch das Kimeta-API Suchprofil verwendet
		// `api/seo/getResourceForUrl?platform=${
		`${getSeoEndpoint()}/getLegacyResourceForUrl?platform=${
			process.env.PLATFORM
		}&url=${encodeURIComponent(url)}`,
	)

export const getSeoStartPage = async (ctx: NextPageContext): Promise<SeoStartPage> => {
	try {
		const result = await getJson<SeoStartPage>(
			ctx.req,
			`${getSeoEndpoint()}/startpage?platform=${process.env.PLATFORM}`,
		)
		return result
	} catch (_) {
		return {
			PageProperties: {
				Title: '',
				H1: '',
				Description: '',
				Keywords: '',
				Name: '',
			},
			LinksToBrowsingPages: [],
			TopSearches: [],
			SeoTextHeading: {
				HtmlTemplate: '',
				ReferencedLink: [],
			},
			SeoText: {
				HtmlTemplate: '',
				ReferencedLink: [],
			},
		}
	}
}

// never called from server!!
export const deleteApplicationFormFile = async (
	applicationId: string,
	fileId: string,
	signal?: AbortSignal,
) => {
	try {
		return apiCall<IApplicationFormResponse>(
			undefined,
			userId =>
				`${getForUserEndpoint()}/${userId}/Application/${applicationId}/File/${fileId}`,
			{
				method: 'DELETE',
				signal,
			},
			[200, 400],
		)
	} catch (error) {
		const response: IApplicationFormResponse = {
			Success: false,
		}

		return response
	}
}

// never called from server!!
export const requestApplicationId = async (documentId: string) => {
	try {
		const formData = new FormData()
		formData.append('documentId', documentId)

		return apiCall<IApplicationFormResponse>(
			undefined,
			userId => `${getForUserEndpoint()}/${userId}/Application`,
			{
				body: formData,
				method: 'POST',
			},
			[200, 400],
		)
	} catch (error) {
		const response: IApplicationFormResponse = {
			Success: false,
		}

		return response
	}
}

// never called from server!!
export const sendApplicationForm = async ({
	applicationId,
	captchaToken,
	email,
	emailCompany,
	firstName,
	lastName,
	legal,
	message,
}: IApplicationFormSend) => {
	try {
		const formData = new FormData()
		formData.append('AgbChecked', legal.toString())
		formData.append('Captcha', captchaToken)
		formData.append('CompanyEMailAddress', emailCompany)
		formData.append('EMailAddress', email)
		formData.append('FirstName', firstName)
		formData.append('LastName', lastName)
		formData.append('Message', message)

		return apiCall<IApplicationFormResponse>(
			undefined,
			userId =>
				`${getForUserEndpoint()}/${userId}/Application/${applicationId}/Send`,
			{
				body: formData,
				method: 'POST',
			},
			[200, 400],
		)
	} catch (error) {
		const response: IApplicationFormResponse = {
			Success: false,
		}

		return response
	}
}

// never called from server!!
export const uploadApplicationFormFile = async (
	applicationId: string,
	fileId: string,
	fileData: File,
	signal?: AbortSignal,
) => {
	try {
		const formData = new FormData()
		formData.append('qqfile', fileData)
		formData.append('qquuid', fileId)

		return apiCall<IApplicationFormResponse>(
			undefined,
			userId =>
				`${getForUserEndpoint()}/${userId}/Application/${applicationId}/File`,
			{
				body: formData,
				method: 'POST',
				signal,
			},
			[200, 400],
		)
	} catch (error) {
		const response: IApplicationFormResponse = {
			Success: false,
		}

		return response
	}
}

export const isUserLoggedIn = (ctx: NextPageContext) => {
	const cookies = getCookies(ctx.req, ctx.res)
	const apiToken = cookies.get('apiToken')
	if (apiToken) {
		try {
			const { sub_anonymous }: AccessToken = jwtDecode(apiToken)
			return sub_anonymous === 'false'
		} catch (_) {}
	}

	if (ctx.req && cookies.get('refreshToken')) {
		return true
	}

	return false
}

export const createNotification = async (ctx: NextPageContext, searchTerms: string[]) => {
	if (await useSsrToken(ctx)) {
		throw new Error('invalid action')
	}
	await apiCall(ctx, () => 'api/notifications', {
		method: 'POST',
		headers: {
			'content-type': 'application/x-www-form-urlencoded',
		},
		body: encode({ searchTerms }),
	})
}

export const deleteNotification = async (ctx: NextPageContext, id: string) => {
	if (await useSsrToken(ctx)) {
		throw new Error('invalid action')
	}
	await apiCall(ctx, () => `api/notifications/${id}`, {
		method: 'DELETE',
	})
}

export const getNotifications = async (ctx: NextPageContext): Promise<Notification[]> => {
	try {
		return await apiCall<Notification[]>(ctx, () => 'api/notifications')
	} catch (err) {
		return []
	}
}

export const getUser = async (ctx: NextPageContext): Promise<User> => {
	return await apiCall<User>(ctx, () => 'api/user')
}

export const setNotification = async (
	ctx: NextPageContext,
	id: string,
	interval: number,
) => {
	if (await useSsrToken(ctx)) {
		throw new Error('invalid action')
	}
	await apiCall(ctx, () => `api/notifications/${id}?${encode({ interval })}`, {
		method: 'PUT',
	})
}
