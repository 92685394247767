import {
	IJobOfferDetails,
	IReferencedLink,
	ISeoText,
	SearchPageQueryParams,
} from './interfaces'
import { NextPageContext } from 'next'
import { skipTrackingParamName } from './mutation/index'
import { ParsedUrlQueryInput } from 'querystring'
import translate from 'config/translate'

const removeSkipTracking = (path: string) =>
	path
		.replace(`?${skipTrackingParamName}=true`, '')
		.replace(`&${skipTrackingParamName}=true`, '')

export const log = (logmsg: string, err?: string) => {
	process.env.NODE_ENV !== 'test' ? console.log(logmsg, err) : null
}

export const logError = (errmsg: string, err?: string) => {
	process.env.NODE_ENV !== 'test' ? console.error(errmsg, err) : null
}

export const asPath = (ctx: NextPageContext) => {
	if (!ctx.asPath) {
		logError('ctx.asPath is not defined using pathname instead', ctx.pathname)
		return ctx.pathname || '/'
	}

	return ctx.req ? removeSkipTracking(ctx.asPath) : ctx.asPath
}

export const pickSingleQueryParam = (query: NextPageContext['query'], param: string) => {
	if (Array.isArray(query[param]) && query[param].length > 0) {
		return query[param][0]
	}

	return query[param] as string
}

const oneDay = 8.64 * Math.pow(10, 7)
const oneWeek = 6.048 * Math.pow(10, 8)
const oneMonth = oneWeek * 4

export const getDateFormat = (firstFound: string, lang: string) => {
	const DateObj = new Date(Date.parse(firstFound))
	const options = { year: 'numeric', month: 'numeric', day: 'numeric' }
	if (isNaN(Date.parse(firstFound)) || Date.parse(firstFound) < 0) {
		return '-'
	}

	if (lang === 'ar') {
		return DateObj.toLocaleDateString('ar-EG', options)
	} else {
		return DateObj.toLocaleDateString(lang, options)
	}
}

export const getTimeAgo = (firstFound: string, lang: string) => {
	const firstFoundTime = Date.parse(firstFound)

	if (isNaN(firstFoundTime) || firstFoundTime < 0) {
		return '-'
	}

	const dateNowTime = new Date().getTime()

	const diff = dateNowTime - firstFoundTime

	//case online < 1 tag
	if (diff >= 0 && diff < oneDay) {
		return translate('heute', lang)
	}

	//case online between 1-7 days
	if (diff >= oneDay && diff < oneWeek) {
		for (let i = 1; i < 7; i++) {
			if (diff >= oneDay * i && diff < oneDay * (i + 1)) {
				if (i === 1) {
					return translate('gestern', lang)
				}

				if (i === 2) {
					return translate('vorgestern', lang)
				}

				if (lang === 'de') {
					return `vor ${i.toString()} Tagen`
				} else {
					return new Date(firstFoundTime).toLocaleDateString(lang)
				}
			}
		}
	}

	if (lang !== 'de') {
		return new Date(firstFoundTime).toLocaleDateString(lang)
	}

	//case online between 1-4 wochen
	if (diff >= oneWeek && diff < oneMonth) {
		for (let i = 1; i < 5; i++) {
			if (diff >= oneWeek * i && diff < oneWeek * (i + 1)) {
				if (i === 1) {
					return 'vor 1 Woche'
				}

				return `vor ${i.toString()} Wochen`
			}
		}
	}

	//case online between 1-36 Monaten
	if (diff >= oneMonth) {
		for (let i = 1; i < 36; i++) {
			if (diff >= oneMonth * i && diff < oneMonth * (i + 1)) {
				if (i === 1) {
					return 'vor 1 Monat'
				}

				return `vor ${i.toString()} Monaten`
			}
		}
	}

	if (diff >= 36 * oneMonth) {
		return 'mehr als 3 Jahre'
	}
}

export const getWebsiteTitle = () => {
	return process.env.WEBSITE_TITLE ? process.env.WEBSITE_TITLE : ''
}

export const getJobWebsiteTitle = (selectedJob: Readonly<IJobOfferDetails>) => {
	const { jobOffer } = selectedJob
	const titleList = []
	const websiteTitle = getWebsiteTitle()

	if (jobOffer.title) {
		titleList.push(jobOffer.title)
	}

	if (jobOffer.companyName) {
		titleList.push(jobOffer.companyName)
	}

	if (jobOffer.location) {
		titleList.push(jobOffer.location)
	}

	return titleList.length > 0
		? `${titleList.join(' - ')} | ${websiteTitle}`
		: `Job | ${websiteTitle}`
}

export const getSearchWebsiteTitle = (query: Readonly<SearchPageQueryParams>) => {
	const titleList = []
	const websiteTitle = getWebsiteTitle()

	if (query.q) {
		titleList.push(`${query.q}`)
	}

	if (query.q || query.loc) {
		titleList.push('Jobs')
	}

	if (query.loc) {
		titleList.push(`in ${query.loc}`)
	}

	return titleList.length > 0
		? `${titleList.join(' ')} | ${websiteTitle}`
		: `Jobsuche | ${websiteTitle}`
}

// TODO:
// Prüfen, ob alle Platzhalter getauscht wurden?
// Prüfen, ob alle Platzhalter verwendet wurden?
export const parseSeoText = (seoText: ISeoText, numberOfResults?: number): string => {
	if (!seoText) {
		return ''
	}

	let { HtmlTemplate } = seoText
	const { ReferencedLink } = seoText

	if (ReferencedLink) {
		ReferencedLink.forEach((value: IReferencedLink, index) => {
			HtmlTemplate = HtmlTemplate.replace(
				'{' + value.PlaceholderId + '}',
				`<a href="${ReferencedLink[index].Link.LinkZiel}">${ReferencedLink[index].Link.LinkText}</a>`,
			)
		})
	}

	if (numberOfResults) {
		HtmlTemplate = HtmlTemplate.replace(
			'{Trefferzahl}',
			numberOfResults.toLocaleString('de').replace(/,/g, '.'),
		)
	}

	return HtmlTemplate
}

export const getSearchTermsFromSearchQuery = (query: SearchPageQueryParams) => {
	const freeTextFilterName =
		process.env.PROJECT !== 'jcbergstrasse'
			? 'freeTextInput'
			: 'jobcenterFreeTextInput'
	const { q, loc, pf, r } = query
	let filters: string[]
	if (pf) {
		if (Array.isArray(pf)) {
			filters = pf
		} else {
			filters = [pf]
		}
	} else {
		filters = []
	}
	const searchTerms = [...filters]
	if (q) {
		searchTerms.push(`${freeTextFilterName}@${q}`)
	}

	if (loc) {
		searchTerms.push(`location@${loc}`)
	}

	if (r) {
		searchTerms.push(`radiusInKm@${r}`)
	}

	return searchTerms
}

export const extendQuery = (
	prev: ParsedUrlQueryInput,
	next: ParsedUrlQueryInput,
	deleteParams?: string[],
) => {
	const query = {
		...prev,
		...next,
	}
	if (deleteParams) {
		deleteParams.forEach(param => delete query[param])
	}
	delete query[skipTrackingParamName]
	delete query['mr-suc']
	delete query['mr-name']
	return query
}

// export const compose = <R>(fn1: (a: R) => R, ...fns: Array<(a: R) => R>) =>
//	fns.reduce((prevFn, nextFn) => value => prevFn(nextFn(value)), fn1)
